import { Link } from "@remix-run/react"

export function ReachRow() {
  return (
    <section id="reach">
      <div className="container">
        <h1>
          Is <em>Renewing Your Mind</em> a Blessing to You?
        </h1>
        <Link className="btn" to="/partnership" data-testid="reach-cta-link">
          Help us reach more people
        </Link>
      </div>
    </section>
  )
}
